<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-text>
        <l-map
          v-if="showMap"
          class="VueLeafMap"
          :zoom="zoom"
          :center="point"
          :options="mapOptions"
          style="height: 400px"
        >
          <l-tile-layer
            :url="url"
            :attribution="attribution"
          />
          <l-marker :lat-lng="point" />
        </l-map>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="toggleModal()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    isVisibleDialog: {
      type: Boolean,
      required: true,
    },
    toggleModal: {
      type: Function,
      default: () => {},
    },
    position: {
      type: Object,
      default: () => ({
        lat: 24.87847,
        lng: 67.0645625,
      }),
    },
  },
  data() {
    return {
      showMap: false,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 13,
      mapOptions: {
        zoomSnap: 0.5,
      },
    };
  },
  computed: {
    point() {
      return latLng(this.position.lat, this.position.lng);
    },
  },
  mounted() {
    setTimeout(() => {
      this.showMap = true;
    }, 100);
  },
};
</script>

<style lang="sass" scoped>
.VueLeafMap
  width: 100%
  height: 400px
</style>
